
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import { Field } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Yup from 'yup';
import { useBus } from '../../../../bus';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import TraineeVue from '@/layout/Trainee.vue';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
    mixins: [apiEndpoint],
    name: 'kt-drawer-trainee-edit',
    components: { Field },
    props: {
        data: { type: Object },
    },

    data() {
        return {
            api_url: '',

            loading: false,
            url: '',
            formData: {
                id: '',
                background_image: '',
                left_signatory: '',
                middle_signatory: '',
                right_signatory: '',
                 fileone: '' as any,
                filetwo: '' as any,
            },
            currentYear: new Date().getFullYear(),
            tranche: [],
            validated: 1,
            entityInfos: [] as any,
            ethnicGroups: [],
               file: '' as any,
            ttt: [],
            targetYears: [],

            load: false,
        };
    },
    async created() {
        this.api_url = this.VUE_APP_API_URL;
        this.load = true;
        await this.getEntityInfos();

        this.emitter.on('certificate-edit', async (data) => {
              this.formData = data;
            console.log(JSON.stringify(data));
            //   this.formData = data;

            // await this.targetLog();
        });
        // await this.getDivision();
    },
    methods: {
        onChange(event) {
            console.log();
            let filename = event.target.files[0]?.name;

            this.file = [];
            this.file = event.target.files ? event.target.files[0] : null;

        },
        async getEntityInfos() {
            this.loading = true;
            let entity_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            await ApiService.get(
                this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
            )
                .then((response) => {
                    this.loading = false;
                    this.entityInfos = response.data.data;
                })
                .catch(({ response }) => {
                    this.loading = false;
                    console.log(response);
                });
        },
        async formSubmit() {
          
            let user_id = VueCookieNext.getCookie('_seip_user');
            let formdata = new FormData();
              formdata.set('id', this.formData.id);
             formdata.set('image1', this.formData.fileone);
            formdata.set('image2', this.formData.filetwo);
            formdata.set('left', this.formData.left_signatory);
            formdata.set('right', this.formData.right_signatory);
                
            await ApiService.post('assessment/certificate/update', formdata)
                .then((response) => {
                    this.loading = false;
                    if (response.status == 200) {
                        Swal.fire({
                            title: 'Success!',
                            text: response.data.data,
                            icon: 'success',
                            buttonsStyling: false,
                            confirmButtonText: 'Ok',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        }).then(() => {
                            DrawerComponent?.hideAll();
                            this.emitter.emit('certificate-updated', true);
                            // this.formData = {
                            //   id: "",
                            //   reference_number: "",
                            //   trainee_name: "",
                            //   gender: "",
                            //   nid: "",
                            //   bcn: "",
                            //   date_of_birth: "",
                            //   present_address: "",
                            //   present_post_code: "",
                            //   present_district: "",
                            //   present_upazilla: "",
                            //   photo: "",
                            //   per_address: "",
                            //   per_post_code: "",
                            //   per_district: "",
                            //   per_upazilla: "",
                            //   home_district: "",
                            //   home_upazilla: "",
                            //   mobile: "",
                            //   alternative_mobile: "",
                            //   religion: "",
                            //   ethnic_group: "",
                            //   highest_class_completed: "",
                            //   highest_class_completed_year: "",
                            //   is_employed: "",
                            //   year_of_experience: "",
                            //   family_monthly_income: "",
                            //   is_physically_challenged: "",
                            //   challenge_remarks: "",
                            //   mother_name: "",
                            //   mother_education_level: "",
                            //   mother_occupation: "",
                            //   father_name: "",
                            //   father_education_level: "",
                            //   father_occupation: "",
                            //   father_annual_income: "",
                            //   have_family_owned_home: "",
                            //   have_family_owned_land: "",
                            //   number_of_siblings: "",
                            // };
                            //this.$router.push("/sign-up");
                        });
                    } else {
                        let err = '';
                        for (const field of Object.keys(response.data.errors)) {
                            err += response.data.errors[field][0] + '<br>';
                        }
                        Swal.fire({
                            title: 'Please check all the required field',
                            html: err,
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: 'Close',
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                        });
                    }
                })
                .catch(({ response }) => {
                    this.loading = false;
                    Swal.fire({
                        title: 'Unknown error',
                        html: response.data.error,
                        icon: 'error',
                        buttonsStyling: false,
                        confirmButtonText: 'Close',
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                    });
                });
        },
    },
    setup() {
        const TraineeListSchema = Yup.object().shape({
            entity_info_id: Yup.string().required().label('Association'),
            tranche_id: Yup.string().required().label('Tranche'),
            target_year_id: Yup.string().required().label('Target Year Id'),
            target_year: Yup.string().required().label('Target Year'),
            target_trainee_number: Yup.string()
                .required()
                .label('Target Trainee Number'),
        });
        return {
            TraineeListSchema,
        };
    },
});
